// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-frequently-asked-questions-index-js": () => import("./../../../src/pages/frequently-asked-questions/index.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-index-js" */),
  "component---src-pages-get-a-cash-offer-confirmation-js": () => import("./../../../src/pages/get-a-cash-offer/confirmation.js" /* webpackChunkName: "component---src-pages-get-a-cash-offer-confirmation-js" */),
  "component---src-pages-get-a-cash-offer-index-js": () => import("./../../../src/pages/get-a-cash-offer/index.js" /* webpackChunkName: "component---src-pages-get-a-cash-offer-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-reviews-index-js": () => import("./../../../src/pages/reviews/index.js" /* webpackChunkName: "component---src-pages-reviews-index-js" */),
  "component---src-pages-where-we-buy-index-js": () => import("./../../../src/pages/where-we-buy/index.js" /* webpackChunkName: "component---src-pages-where-we-buy-index-js" */)
}

