import React, { createContext, useState } from "react"

export const UserContext = createContext({})

const UserProvider = ({ children }) => {
  const [place, updatePlace] = useState({})

  return (
    <UserContext.Provider value={{ place, updatePlace }}>
      {children}
    </UserContext.Provider>
  )
}
export default ({ element }) => <UserProvider>{element}</UserProvider>
